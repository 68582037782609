function truncateText(str, lengthStr = 45) {
    if (checkLength(str, lengthStr)) {
        return str.substr(0, lengthStr) + '...';        
    }
    return str;
}

function checkLength(string, lengthStr = 45) {
  return ![undefined, null].includes(string) && string.length > lengthStr;
}

function capitalize(string) {
    if (string) {
      let withoutUnderline = string.split('_').join(' ').toLowerCase();
      return withoutUnderline[0].toUpperCase() + withoutUnderline.slice(1);
    }
}

module.exports = {
    truncateText: truncateText,
    checkLength: checkLength,
    capitalize: capitalize
}