<template>
  <div>
    <v-card class="table-auto-analysis-container">
      <v-card-title>
        <TableSearch
          :label="txt.tableSearchLabel"
          v-model="filter.search"
          @input="(items) => fetchSearchAnalysis(items)"
          :disabled="loadingTable"
        ></TableSearch>
        <v-spacer></v-spacer>
        <ExportButton :is-disabled="false" class="btn-export" @click="exportarAnalysis" />
      </v-card-title>
      <div id="analysis_container">
        <div :id="idTable + '_scrollDuplicate'" class="table_scroll_duplicate">
          <div :id="idTable + '_dummy'"></div>
        </div>
        <v-data-table
          :id="idTable"
          :loading="loadingTable"
          :search="search"
          :headers="headers"
          :options.sync="pagination"
          :items="analysisList"
          :page="pagination.page"
          :server-items-length="totalAnalysis"
          :footer-props="{
            disablePagination: analysisList.length === 0 || loadingTable,
            itemsPerPageOptions:
              totalAnalysis < 50
                ? [
                    { value: 50, text: '50' },
                    { value: 100, text: '100', disabled: true },
                    { value: 250, text: '250', disabled: true },
                  ]
                : totalAnalysis < 100
                ? [
                    { value: 50, text: '50' },
                    { value: 100, text: '100' },
                    { value: 250, text: '250', disabled: true },
                  ]
                : [
                    { value: 50, text: '50' },
                    { value: 100, text: '100' },
                    { value: 250, text: '250' },
                  ],
            disableItemsPerPage: analysisList.length === 0 || loadingTable,
            itemsPerPageText: `Rubricas por página: `,
            expandIcon: 'mdi-dots-horizontal',
          }"
          :no-data-text="`Não existem rubricas a serem exibidas.`"
        >
          <template v-slot:footer.page-text="items">
            {{ loadingTable ? 0 : items.pageStart }} - {{ loadingTable ? 0 : items.pageStop }} de
            {{ loadingTable ? 0 : items.itemsLength }} rubricas
          </template>

          <template slot="body" v-if="loadingTable">
            <tr>
              <td v-for="loadItem in headers">
                <v-skeleton-loader :max-width="loadItem.width" class="loaderCss"></v-skeleton-loader>
              </td>
            </tr>
          </template>

          <template v-slot:no-data>
            <div id="noDataText" class="d-flex justify-center" :style="getTableWidth">
              <p>Não há registros de {{ tableTitle }} a serem exibidos.</p>
            </div>
          </template>

          <template v-slot:item.socialName="{ item }">
            <span class="employer">
              <AGTooltip :tooltipText="item.socialName" v-if="checkLength(item.socialName)">
                <template slot:tooltipArea>
                  <div class="text-no-wrap text-truncate">
                    {{ item.socialName }}
                  </div>
                </template>
              </AGTooltip>
              <div class="text-no-wrap text-truncate" v-else>
                {{ item.socialName }}
              </div>
              <div class="employer-cnpj">
                {{ item.cnpj | cnpj }}
              </div>
            </span>
          </template>

          <template v-slot:item.rubricDesc="{ item }">
            <AGTooltip v-if="checkLength(item.rubricDesc, 45)" :tooltipText="item.rubricDesc">
              <template slot:tooltipArea>
                <span class="text-no-wrap">
                  {{ truncateText(item.rubricDesc, 45) }}
                </span>
              </template>
            </AGTooltip>
            <div v-else>
              <span class="text-no-wrap">
                {{ item.rubricDesc }}
              </span>
            </div>
          </template>

          <template v-slot:item.vigente="{ item }">
            <div v-if="item.vigente">Sim</div>
            <div v-else>Não</div>
          </template>

          <template v-slot:item.classification="{ item }">
            <v-chip v-if="item.classification && item.classification == 'Com risco de uso'" class="v-chip-danger">{{
              item.classification
            }}</v-chip>
            <v-chip v-if="item.classification && item.classification == 'Pendente de análise'" class="v-chip-warning">{{
              item.classification
            }}</v-chip>
            <v-chip v-if="item.classification && item.classification == 'Disponível para uso'" class="v-chip-success">{{
              item.classification
            }}</v-chip>
          </template>

          <template v-slot:item.exception="{ item }">
            <div v-if="item.exception">Sim</div>
            <div v-else>Não</div>
          </template>

          <template v-slot:item.natureName="{ item }">
            <div v-if="item.natureName">
              <span class="mr-5">{{ item.natureCode }} - {{ item.natureName }}</span>
              <v-chip v-if="item.natureName && item.natRubrStatus != 'Ok'" class="v-chip-danger">{{
                item.natRubrStatus
              }}</v-chip>
            </div>
          </template>

          <template v-slot:item.rubricTypeCode="{ item }">
            <span class="mr-5">{{ item.rubricTypeCode }}</span>
            <v-chip v-if="item.rubricTypeCode && item.tpRubrStatus != 'Ok'" class="v-chip-danger">{{
              item.tpRubrStatus
            }}</v-chip>
          </template>

          <template v-slot:item.inssIncidenceCode="{ item }">
            <span class="mr-5">{{ item.inssIncidenceCode }}</span>
            <v-chip v-if="item.inssIncidenceCode && item.codIncCpStatus != 'Ok'" class="v-chip-danger">{{
              item.codIncCpStatus
            }}</v-chip>
          </template>

          <template v-slot:item.irrfIncidenceCode="{ item }">
            <span class="mr-5">{{ item.irrfIncidenceCode }}</span>
            <v-chip v-if="item.irrfIncidenceCode && item.codIncIrrfStatus != 'Ok'" class="v-chip-danger">{{
              item.codIncIrrfStatus
            }}</v-chip>
          </template>

          <template v-slot:item.fgtsIncidenceCode="{ item }">
            <span class="mr-5">{{ item.fgtsIncidenceCode }}</span>
            <v-chip v-if="item.fgtsIncidenceCode && item.codIncFgtsStatus != 'Ok'" class="v-chip-danger">{{
              item.codIncFgtsStatus
            }}</v-chip>
          </template>

          <template v-slot:item.cprpIncidenceCode="{ item }">
            <span class="mr-5">{{ item.cprpIncidenceCode }}</span>
            <v-chip v-if="item.cprpIncidenceCode && item.codIncCprpStatus != 'Ok'" class="v-chip-danger">{{
              item.codIncCprpStatus
            }}</v-chip>
          </template>

          <template v-slot:item.usesTetoRemun="{ item }">
            <div v-if="item.usesTetoRemun">Sim</div>
            <div v-else>Não</div>
          </template>

          <template v-slot:item.natureDesc="{ item }">
            <AGTooltip v-if="checkLength(item.natureDesc, 45)" :tooltipText="item.natureDesc">
              <template slot:tooltipArea>
                <span class="text-no-wrap">
                  {{ truncateText(item.natureDesc, 45) }}
                </span>
              </template>
            </AGTooltip>
            <div v-else>
              <span class="text-no-wrap">
                {{ item.natureDesc }}
              </span>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { eventBus } from '@/main.js';
import { truncateText, checkLength } from '@/utils/text.js';
import TableMixin from "@/mixins/Table.js"
export default {
  name: 'TableAutoAnalysis',
  components: {
    TableSearch: () => import('../../commons/TableSearch.vue'),
    ExportButton: () => import('../../commons/ExportButton.vue'),
    AGTooltip: () => import('../../commons/AGTooltip.vue'),
    Tag: () => import('../../commons/Tag.vue'),
  },
  mixins: [TableMixin],
  data() {
    return {
      idTable: 'tablea',
      tableTitle: 'rubricas',
      search: '',
      txt: {
        tableSearchLabel: 'Pesquisar por Id. da tabela, código, nome, descrição ou natureza',
      },
      headers: [
        { text: '', value: 'id_column', width: '41px', sortable: false },
        { text: 'Empregador', value: 'socialName', width: '330px', sortable: false },
        { text: 'Id. da tabela', value: 'tableId', width: '112px', sortable: false },
        { text: 'Código', value: 'rubricCode', width: '78px', sortable: false },
        { text: 'Nome', value: 'rubricDesc', width: '192px', sortable: false },
        { text: 'Vigente', value: 'vigente', width: '82px', sortable: false },
        { text: 'Aplicação de recomendações', value: 'rectificationInfo', width: '229px', sortable: false },
        { text: 'Etapa de análise', value: 'classificationGroup', width: '272px', sortable: false },
        { text: 'Classificação de análise', value: 'classification', width: '217px', sortable: false },
        { text: 'Nível de risco', value: '', width: '128px', sortable: false },
        { text: 'Exceção', value: 'exception', width: '104px', sortable: false },
        { text: 'Natureza', value: 'natureName', width: '697px', sortable: false },
        { text: 'Descrição da natureza', value: 'natureDesc', width: '380px', sortable: false },
        { text: 'Tipo', value: 'rubricTypeCode', width: '180px', sortable: false },
        { text: 'CP', value: 'inssIncidenceCode', width: '175px', sortable: false },
        { text: 'IRRF', value: 'irrfIncidenceCode', width: '175px', sortable: false },
        { text: 'FGTS', value: 'fgtsIncidenceCode', width: '174px', sortable: false },
        { text: 'CPRP', value: 'cprpIncidenceCode', width: '159px', sortable: false },
        { text: 'Teto remuneratório', value: 'usesTetoRemun', width: '225px', sortable: false },
        { text: 'Observação (e-Social)', value: 'natureDesc', width: '380px', sortable: false },
        { text: 'Usuário', value: 'user', width: '149px', sortable: false },
        { text: 'Última atualização', value: 'updatedAt', width: '195px', sortable: false },
      ],
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
    };
  },

  computed: {
    ...mapGetters({
      loadingTable: 'esocial/esocialRubricas/getLoadingFilters',
      filter: 'esocial/esocialRubricas/getFiltersSaved',
      analysisList: 'esocial/esocialRubricas/getAnalysisList',
      scheduleReport: 'esocial/esocialRubricas/getScheduleReportStatus',
      paginationFilter: 'esocial/esocialRubricas/getPagination',
      totalAnalysis: 'esocial/esocialRubricas/getTotalAnalysis',
    }),
    pagination: {
      get: function () {
        return this.paginationFilter;
      },
      set: function (value) {
        this.setPagination(value);
      },
    },
    isDisabled() {
      return this.loadingTable;
    },
  },

  methods: {
    checkLength,
    truncateText,
    ...mapActions('esocial/esocialRubricas', ['init', 'setPagination', 'fetchSearchAnalysis', 'exportAutoAnalysis']),
    async exportarAnalysis() {
      await this.exportAutoAnalysis();
      if (this.scheduleReport) {
        this.showToast(
          'Recebemos seu pedido com sucesso! Nos menus Agnes em: Documentos e Download. Assim que concluído, o arquivo estará disponível.',
          'success',
          78,
        );
      } else {
        this.showToast('Erro ao exportar relatório.', 'error', 78);
      }
    },
    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/design/components/table.scss';
@import './src/design/components/tooltip.scss';
@import './src/design/variables.scss';
.table-auto-analysis-container {
  padding: 0;
  border: $border-width-hairline $border-default $neutral-color-low-dark;
}
.btn-export {
  border: 1px solid var(--v-primaryMedium-base) !important;
}
.btn-export:hover {
  border: 1px solid var(--v-highlightLight-base) !important;
}
.employer {
  div {
    max-width: 380px;
    min-width: 320px;
  }
  .employer-cnpj {
    font-size: $font-size-xxs;
    line-height: $line-height-md;
  }
}
</style>
