
export default {
  mounted() {
    this.updateScrollers();
    this.setScrollersEventListener();
  },
  methods: {
    setScrollersEventListener() {
      window.addEventListener('resize', this.updateScrollers);
      const linkScroller = (a, b) => {
        a.addEventListener('scroll', (e) => {
          b.scrollLeft = e.target.scrollLeft;
        });
      };
      const _a = this.$el.querySelector(`#${this.idTable}_scrollDuplicate`);
      const _b = this.$el.querySelector('.v-data-table__wrapper');
      linkScroller(_a, _b);
      linkScroller(_b, _a);
    },
    updateScrollers() {
      const dummy = this.$el.querySelector('#' + this.idTable + '_dummy');
      const _b = this.$el.querySelector('.v-data-table__wrapper');
      dummy.style.width = _b.scrollWidth + 'px';
    },
    onTableInput() {
      //waits the page finishes loading data on the table DOM and updates the scroll width
      setTimeout(() => {
        this.updateScrollers();
      }, 250);
    }
  },
  computed: {
    getTableWidth() {
      let table_wrapper = this.$el.querySelector('.v-data-table__wrapper');
      return {width: table_wrapper.offsetWidth + 'px'};
    }
  }
}