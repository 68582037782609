var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "table-auto-analysis-container" },
        [
          _c(
            "v-card-title",
            [
              _c("TableSearch", {
                attrs: {
                  label: _vm.txt.tableSearchLabel,
                  disabled: _vm.loadingTable,
                },
                on: { input: (items) => _vm.fetchSearchAnalysis(items) },
                model: {
                  value: _vm.filter.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.filter, "search", $$v)
                  },
                  expression: "filter.search",
                },
              }),
              _c("v-spacer"),
              _c("ExportButton", {
                staticClass: "btn-export",
                attrs: { "is-disabled": false },
                on: { click: _vm.exportarAnalysis },
              }),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { id: "analysis_container" } },
            [
              _c(
                "div",
                {
                  staticClass: "table_scroll_duplicate",
                  attrs: { id: _vm.idTable + "_scrollDuplicate" },
                },
                [_c("div", { attrs: { id: _vm.idTable + "_dummy" } })]
              ),
              _c(
                "v-data-table",
                {
                  attrs: {
                    id: _vm.idTable,
                    loading: _vm.loadingTable,
                    search: _vm.search,
                    headers: _vm.headers,
                    options: _vm.pagination,
                    items: _vm.analysisList,
                    page: _vm.pagination.page,
                    "server-items-length": _vm.totalAnalysis,
                    "footer-props": {
                      disablePagination:
                        _vm.analysisList.length === 0 || _vm.loadingTable,
                      itemsPerPageOptions:
                        _vm.totalAnalysis < 50
                          ? [
                              { value: 50, text: "50" },
                              { value: 100, text: "100", disabled: true },
                              { value: 250, text: "250", disabled: true },
                            ]
                          : _vm.totalAnalysis < 100
                          ? [
                              { value: 50, text: "50" },
                              { value: 100, text: "100" },
                              { value: 250, text: "250", disabled: true },
                            ]
                          : [
                              { value: 50, text: "50" },
                              { value: 100, text: "100" },
                              { value: 250, text: "250" },
                            ],
                      disableItemsPerPage:
                        _vm.analysisList.length === 0 || _vm.loadingTable,
                      itemsPerPageText: `Rubricas por página: `,
                      expandIcon: "mdi-dots-horizontal",
                    },
                    "no-data-text": `Não existem rubricas a serem exibidas.`,
                  },
                  on: {
                    "update:options": function ($event) {
                      _vm.pagination = $event
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "footer.page-text",
                      fn: function (items) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm.loadingTable ? 0 : items.pageStart) +
                              " - " +
                              _vm._s(_vm.loadingTable ? 0 : items.pageStop) +
                              " de " +
                              _vm._s(_vm.loadingTable ? 0 : items.itemsLength) +
                              " rubricas "
                          ),
                        ]
                      },
                    },
                    {
                      key: "no-data",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex justify-center",
                              style: _vm.getTableWidth,
                              attrs: { id: "noDataText" },
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  "Não há registros de " +
                                    _vm._s(_vm.tableTitle) +
                                    " a serem exibidos."
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "item.socialName",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "span",
                            { staticClass: "employer" },
                            [
                              _vm.checkLength(item.socialName)
                                ? _c(
                                    "AGTooltip",
                                    { attrs: { tooltipText: item.socialName } },
                                    [
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-no-wrap text-truncate",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.socialName) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                    ],
                                    2
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass: "text-no-wrap text-truncate",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.socialName) + " "
                                      ),
                                    ]
                                  ),
                              _c("div", { staticClass: "employer-cnpj" }, [
                                _vm._v(
                                  " " + _vm._s(_vm._f("cnpj")(item.cnpj)) + " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.rubricDesc",
                      fn: function ({ item }) {
                        return [
                          _vm.checkLength(item.rubricDesc, 45)
                            ? _c(
                                "AGTooltip",
                                { attrs: { tooltipText: item.rubricDesc } },
                                [
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-no-wrap" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.truncateText(
                                                item.rubricDesc,
                                                45
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                ],
                                2
                              )
                            : _c("div", [
                                _c("span", { staticClass: "text-no-wrap" }, [
                                  _vm._v(" " + _vm._s(item.rubricDesc) + " "),
                                ]),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "item.vigente",
                      fn: function ({ item }) {
                        return [
                          item.vigente
                            ? _c("div", [_vm._v("Sim")])
                            : _c("div", [_vm._v("Não")]),
                        ]
                      },
                    },
                    {
                      key: "item.classification",
                      fn: function ({ item }) {
                        return [
                          item.classification &&
                          item.classification == "Com risco de uso"
                            ? _c("v-chip", { staticClass: "v-chip-danger" }, [
                                _vm._v(_vm._s(item.classification)),
                              ])
                            : _vm._e(),
                          item.classification &&
                          item.classification == "Pendente de análise"
                            ? _c("v-chip", { staticClass: "v-chip-warning" }, [
                                _vm._v(_vm._s(item.classification)),
                              ])
                            : _vm._e(),
                          item.classification &&
                          item.classification == "Disponível para uso"
                            ? _c("v-chip", { staticClass: "v-chip-success" }, [
                                _vm._v(_vm._s(item.classification)),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "item.exception",
                      fn: function ({ item }) {
                        return [
                          item.exception
                            ? _c("div", [_vm._v("Sim")])
                            : _c("div", [_vm._v("Não")]),
                        ]
                      },
                    },
                    {
                      key: "item.natureName",
                      fn: function ({ item }) {
                        return [
                          item.natureName
                            ? _c(
                                "div",
                                [
                                  _c("span", { staticClass: "mr-5" }, [
                                    _vm._v(
                                      _vm._s(item.natureCode) +
                                        " - " +
                                        _vm._s(item.natureName)
                                    ),
                                  ]),
                                  item.natureName && item.natRubrStatus != "Ok"
                                    ? _c(
                                        "v-chip",
                                        { staticClass: "v-chip-danger" },
                                        [_vm._v(_vm._s(item.natRubrStatus))]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "item.rubricTypeCode",
                      fn: function ({ item }) {
                        return [
                          _c("span", { staticClass: "mr-5" }, [
                            _vm._v(_vm._s(item.rubricTypeCode)),
                          ]),
                          item.rubricTypeCode && item.tpRubrStatus != "Ok"
                            ? _c("v-chip", { staticClass: "v-chip-danger" }, [
                                _vm._v(_vm._s(item.tpRubrStatus)),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "item.inssIncidenceCode",
                      fn: function ({ item }) {
                        return [
                          _c("span", { staticClass: "mr-5" }, [
                            _vm._v(_vm._s(item.inssIncidenceCode)),
                          ]),
                          item.inssIncidenceCode && item.codIncCpStatus != "Ok"
                            ? _c("v-chip", { staticClass: "v-chip-danger" }, [
                                _vm._v(_vm._s(item.codIncCpStatus)),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "item.irrfIncidenceCode",
                      fn: function ({ item }) {
                        return [
                          _c("span", { staticClass: "mr-5" }, [
                            _vm._v(_vm._s(item.irrfIncidenceCode)),
                          ]),
                          item.irrfIncidenceCode &&
                          item.codIncIrrfStatus != "Ok"
                            ? _c("v-chip", { staticClass: "v-chip-danger" }, [
                                _vm._v(_vm._s(item.codIncIrrfStatus)),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "item.fgtsIncidenceCode",
                      fn: function ({ item }) {
                        return [
                          _c("span", { staticClass: "mr-5" }, [
                            _vm._v(_vm._s(item.fgtsIncidenceCode)),
                          ]),
                          item.fgtsIncidenceCode &&
                          item.codIncFgtsStatus != "Ok"
                            ? _c("v-chip", { staticClass: "v-chip-danger" }, [
                                _vm._v(_vm._s(item.codIncFgtsStatus)),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "item.cprpIncidenceCode",
                      fn: function ({ item }) {
                        return [
                          _c("span", { staticClass: "mr-5" }, [
                            _vm._v(_vm._s(item.cprpIncidenceCode)),
                          ]),
                          item.cprpIncidenceCode &&
                          item.codIncCprpStatus != "Ok"
                            ? _c("v-chip", { staticClass: "v-chip-danger" }, [
                                _vm._v(_vm._s(item.codIncCprpStatus)),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "item.usesTetoRemun",
                      fn: function ({ item }) {
                        return [
                          item.usesTetoRemun
                            ? _c("div", [_vm._v("Sim")])
                            : _c("div", [_vm._v("Não")]),
                        ]
                      },
                    },
                    {
                      key: "item.natureDesc",
                      fn: function ({ item }) {
                        return [
                          _vm.checkLength(item.natureDesc, 45)
                            ? _c(
                                "AGTooltip",
                                { attrs: { tooltipText: item.natureDesc } },
                                [
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-no-wrap" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.truncateText(
                                                item.natureDesc,
                                                45
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                ],
                                2
                              )
                            : _c("div", [
                                _c("span", { staticClass: "text-no-wrap" }, [
                                  _vm._v(" " + _vm._s(item.natureDesc) + " "),
                                ]),
                              ]),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm.loadingTable
                    ? _c("template", { slot: "body" }, [
                        _c(
                          "tr",
                          _vm._l(_vm.headers, function (loadItem) {
                            return _c(
                              "td",
                              [
                                _c("v-skeleton-loader", {
                                  staticClass: "loaderCss",
                                  attrs: { "max-width": loadItem.width },
                                }),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }